body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

:root, [data-joy-color-scheme="light"] {
  --joy-fontFamily-display: "Instrument Sans", "Inter", sans-serif !important;
  --joy-fontFamily-body: "Instrument Sans", "Inter", sans-serif !important;

  --joy-palette-main-50: #cfbcff;
  --joy-palette-main-100: #cebdff;
  --joy-palette-main-200: #cebdff;
  --joy-palette-main-300: #845de6;
  --joy-palette-main-400: #6a42cb;
  --joy-palette-main-500: #5e33be;
  --joy-palette-main-600: #5223b2;
  --joy-palette-main-700: #460ea7;
  --joy-palette-main-800: #3a0092;
  --joy-palette-main-900: #22005d;

  /* Green palette */
  --joy-palette-nature-50: #b6d167;
  --joy-palette-nature-100: #9bb54f;
  --joy-palette-nature-200: #9bb54f;
  --joy-palette-nature-300: #68801f;
  --joy-palette-nature-400: #506600;
  --joy-palette-nature-500: #465900;
  --joy-palette-nature-600: #3c4d00;
  --joy-palette-nature-700: #324100;
  --joy-palette-nature-800: #283500;
  --joy-palette-nature-900: #161f00;

  /* Masters' Green palette */
  --joy-palette-mastersgreen-50: #bfffda;
  --joy-palette-mastersgreen-100: #71dba6;
  --joy-palette-mastersgreen-200: #35a373;
  --joy-palette-mastersgreen-300: #05885b;
  --joy-palette-mastersgreen-400: #006c47;
  --joy-palette-mastersgreen-500: #005f3e;
  --joy-palette-mastersgreen-600: #005235;
  --joy-palette-mastersgreen-700: #00452c;
  --joy-palette-mastersgreen-800: #003823;
  --joy-palette-mastersgreen-900: #002113;

  /* Ocean blue palette */
  --joy-palette-ocean-50: #6fd2ff;
  --joy-palette-ocean-100: #45b7e6;
  --joy-palette-ocean-200: #45b7e6;
  --joy-palette-ocean-300: #0081a8;
  --joy-palette-ocean-400: #006686;
  --joy-palette-ocean-500: #005976;
  --joy-palette-ocean-600: #004d66;
  --joy-palette-ocean-700: #004156;
  --joy-palette-ocean-800: #003547;
  --joy-palette-ocean-900: #001e2b;

  /* Deep Blue palette */
  --joy-palette-deepblue-50: #c1c1ff;
  --joy-palette-deepblue-100: #a1a2ff;
  --joy-palette-deepblue-200: #8183ff;
  --joy-palette-deepblue-300: #6062ff;
  --joy-palette-deepblue-400: #413ef6;
  --joy-palette-deepblue-500: #332ceb;
  --joy-palette-deepblue-600: #2313e1;
  --joy-palette-deepblue-700: #1800c9;
  --joy-palette-deepblue-800: #1300a9;
  --joy-palette-deepblue-900: #08006c;

  /* Night palette */
  --joy-palette-night-50: #F5F5F5;
  --joy-palette-night-100: #d8d8d8;
  --joy-palette-night-200: #c1c1c1;
  --joy-palette-night-300: #a7a7a7;
  --joy-palette-night-400: #8d8d8d;
  --joy-palette-night-500: #737373;
  --joy-palette-night-600: #585858;
  --joy-palette-night-700: #3e3e3e;
  --joy-palette-night-800: #242424;
  --joy-palette-night-900: #0a0a0a;

  /* Red palette */
  --joy-palette-red-50: #ffb2b9;
  --joy-palette-red-100: #ff8796;
  --joy-palette-red-200: #ff5071;
  --joy-palette-red-300: #de3158;
  --joy-palette-red-400: #ba1042;
  --joy-palette-red-500: #a70038;
  --joy-palette-red-600: #91002f;
  --joy-palette-red-700: #7c0027;
  --joy-palette-red-800: #67001f;
  --joy-palette-red-900: #400010;
}
